import {
  getBaseApiHelper,
  deleteBaseApiHelper,
  getAccountId,
  patchBaseApiHelper
} from '../utils/apiHelpers'

import { useExternalValues } from "../utils/sampleHelpers"

import { appStore } from '../../index'

// Action Types
export const SAMPLES_REQUEST = 'SAMPLES_REQUEST'
export const SAMPLES_SUCCESS = 'SAMPLES_SUCCESS'
export const SAMPLES_FAILURE = 'SAMPLES_FAILURE'

export const DRAG_MARKER = 'DRAG_MARKER'
export const DROP_MARKER = 'DROP_MARKER'
export const SET_ACTIVE_SAMPLEMARKER = 'SET_ACTIVE_SAMPLEMARKER'
export const RESET_MARKERS = 'RESET_MARKERS'

export const DELETE_SAMPLE_REQUEST = 'DELETE_SAMPLE_REQUEST'
export const DELETE_SAMPLE_SUCCESS = 'DELETE_SAMPLE_SUCCESS'
export const DELETE_SAMPLE_FAILURE = 'DELETE_SAMPLE_FAILURE'

export const SAMPLE_PATCH_REQUEST = 'SAMPLE_PATCH_REQUEST'
export const SAMPLE_PATCH_SUCCESS = 'SAMPLE_PATCH_SUCCESS'
export const SAMPLE_PATCH_FAILURE = 'SAMPLE_PATCH_FAILURE'

// Action Creators
function samplesRequest(accountId) {
  return {
    type: SAMPLES_REQUEST,
    accountId
  }
}

function samplesSuccess(samples, accountId, useCache) {
  return {
    type: SAMPLES_SUCCESS,
    samples: samples,
    accountId,
    useCache
  }
}

function samplesFailure(error, accountId) {
  return {
    type: SAMPLES_FAILURE,
    error,
    accountId
  }
}


// Thunks/Async Action Creators
export function getSamples(accountId, useSelectedAccounts = false, updateCache=false) {
  const store = appStore.getState()
  const release = store.auth.user.release
  if (useSelectedAccounts) {
    accountId = store.auth.selectedAccounts?.[0] || getAccountId()
  } else {
    accountId = accountId || getAccountId()
  }
  const { samplesByAccount } = store.entities.samples
  return dispatch => {
    if (samplesByAccount[accountId] && !updateCache) {
      return dispatch(samplesSuccess(null, accountId, true))
    }
    dispatch(samplesRequest(accountId))
    return getBaseApiHelper(`/account/${accountId}/samples?include_values=true&release=${release}`)
      .then(response => {
        response.data = useExternalValues(response.data)
        dispatch(samplesSuccess(response.data, accountId))
        return response.data
      })
      .catch(error => {
        dispatch(samplesFailure(error.response, accountId))
        return error.response
      })
  }
}

// Thunks/Async Action Creators

export function dragMarker(sampleId) {
  /*
    expect sampleId to be a string with the id of a sample
  */
  return {
    type: DRAG_MARKER,
    sampleId
  }
}

export function dropMarker(marker) {
  /*
    marker should be an object of the following shape:
    {sampleId: float, latitude: float, longitude: string}
  */
  marker['active'] = true
  return {
    type: DROP_MARKER,
    marker
  }
}

export function resetMarkers(sample) {
  /*
    removes marker for for new sample and
    resets lat/long on cancel
  */
  return {
    type: RESET_MARKERS,
    sample
  }
}

/**
Delete samples from an order
**/
function deleteSampleRequest() {
  return {
    type: DELETE_SAMPLE_REQUEST
  }
}

function deleteSampleSuccess(sample) {
  return {
    type: DELETE_SAMPLE_SUCCESS,
    sample
  }
}

function deleteSampleFailure(error) {
  return {
    type: DELETE_SAMPLE_FAILURE,
    error
  }
}

export function setActiveSampleMarker(sampleId) {
  return {
    type: SET_ACTIVE_SAMPLEMARKER,
    sampleId
  }
}

export function deleteSample(sample) {
  return dispatch => {
    dispatch(deleteSampleRequest())
    return deleteBaseApiHelper(`/samples/${sample.id}`)
      .then(() => {
        dispatch(deleteSampleSuccess(sample))
        return Promise.resolve()
      })
      .catch(rejected => {
        dispatch(deleteSampleFailure(rejected))
        return Promise.reject()
      })
  }
}

/**
Patch sample
**/
function patchSampleRequest() {
  return {
    type: SAMPLE_PATCH_REQUEST
  }
}

function patchSampleSuccess(sample) {
  return {
    type: SAMPLE_PATCH_SUCCESS,
    sample: sample
  }
}

function patchSampleFailure(error) {
  return {
    type: SAMPLE_PATCH_FAILURE,
    error
  }
}

export function patchSample(sample) {
  return dispatch => {
    dispatch(patchSampleRequest())
    return patchBaseApiHelper(`/samples/${sample.id}`, sample)
      .then(resp => {
        dispatch(patchSampleSuccess(resp.data))
        return Promise.resolve()
      })
      .catch(rejected => {
        dispatch(patchSampleFailure(rejected))
        return Promise.reject(rejected)
      })
  }
}

