import {
  SELECT_FARM_FOR_DETAIL,

  GET_ACCOUNT_FARMS_REQUEST ,
  GET_ACCOUNT_FARMS_FAILURE,
  GET_ACCOUNT_FARMS_SUCCESS,

  GET_FARM_REQUEST,
  GET_FARM_SUCCESS,
  GET_FARM_FAILURE,

  FARM_POST_REQUEST,
  FARM_POST_SUCCESS,
  FARM_POST_FAILURE,

  FARMS_POST_REQUEST,
  FARMS_POST_SUCCESS,
  FARMS_POST_FAILURE,

  FARM_PATCH_REQUEST,
  FARM_PATCH_SUCCESS,
  FARM_PATCH_FAILURE,

  DELETE_FARM_REQUEST,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_FAILURE,
} from './farmActions'

import { createSelector } from 'reselect'
import { getAccountId } from '../utils/apiHelpers'

const initialState = {
  pending: false,
  hasError: false,
  error: null,
  farmEntities: {},
  selectedFarmId: '',
}

export const farmsReducer = (state = { ...initialState }, action) => {
  switch(action.type) {

    ////////////////////////
    // Get Handlers
    ////////////////////////
    case GET_ACCOUNT_FARMS_REQUEST: {
      return { ...state, pending: action.isFetching }
    }

    case GET_ACCOUNT_FARMS_SUCCESS: {
      const acctFarms = {}
      action.payload.forEach(entity => {
        acctFarms[entity.id] = entity
      })
      return {
        ...state,
        pending: false,
        hasError: false,
        farmEntities: {
          ...state.farmEntities,
          [action.accountId]: acctFarms
        }
      }
    }

    case  GET_ACCOUNT_FARMS_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case GET_FARM_REQUEST: {
      return {
        ...state,
        pending: true
      }
    }

    case GET_FARM_SUCCESS: {
      return {
        ...state,
        pending: false,
        farmEntities: {
          ...state.farmEntities,
          [action.farm.account_id]: {
            ...state.farmEntities[action.farm.account_id],
            [action.farm.id]: action.farm
          }
        }
      }
    }

    case GET_FARM_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case SELECT_FARM_FOR_DETAIL: {
      return {
        ...state,
        selectedFarmId: action.farmId
      }
    }

    ////////////////////////
    // Create/Update Farm Handlers
    ////////////////////////
    case FARM_POST_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case FARM_POST_SUCCESS: {
      const farmEntities = { ...state.farmEntities }
      const accountId = action.payload.account_id
      farmEntities[accountId] = {
        ...farmEntities[accountId],
        [action.payload.id]: action.payload
      }
      return {
        ...state,
        pending: false,
        hasError: false,
        farmEntities
      }
    }
    case FARM_POST_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case FARMS_POST_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case FARMS_POST_SUCCESS: {
      const farmEntities = { ...state.farmEntities }
      const accountId = action.payload[0].account_id
      if (!farmEntities[accountId]) {
        farmEntities[accountId] = {}
      }
      const accountFarms = farmEntities[accountId]
      action.payload.forEach(farm => {
        accountFarms[farm.id] = { ...farm }
      })
      return {
        ...state,
        pending: false,
        hasError: false,
        farmEntities
      }
    }
    case FARMS_POST_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case FARM_PATCH_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case FARM_PATCH_SUCCESS: {
      const farmEntities = { ...state.farmEntities }
      const accountId = action.payload.account_id
      farmEntities[accountId] = {
        ...farmEntities[accountId],
        [action.payload.id]: [action.payload]
      }
      return {
        ...state,
        pending: false,
        hasError: false,
        farmEntities
      }
    }
    case FARM_PATCH_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    ////////////////////////
    // Delete Handlers
    ////////////////////////
    case DELETE_FARM_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case DELETE_FARM_SUCCESS: {
      const { farmId } = action
      delete state.farmEntities[farmId]
      const newFarmEntities = Object.assign({}, state.farmEntities)
      return {
        ...state,
        pending: false,
        hasError: false,
        farmEntities: newFarmEntities
      }
    }

    case DELETE_FARM_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}



const getSelectedAccountId = state => state.auth.selectedAccounts?.[0] || getAccountId()
const getSelectedAccountIds = state => state.auth.selectedAccounts
const getFarmsByAccount = state => state.entities.farms.farmEntities
const getAccountFarms = (farms, acct) => {
  acct = acct || getAccountId()
  let allfarms =  {...farms}
  return allfarms[acct]
}
const getSelectedAccountFarms = (farms, accounts) => (
  accounts.reduce((acc, acct) => {
    if (farms[acct]) {
      acc = { ...acc, ...farms[acct] }
    }
    return acc
  }, {})
)
export const getOwnFarms = createSelector(
  getFarmsByAccount,
  getAccountFarms
)
export const getFarmsForSelectedAccount = createSelector(
  getFarmsByAccount,
  getSelectedAccountId,
  getAccountFarms
)
export const getFarmsForSelectedAccounts = createSelector(
  getFarmsByAccount,
  getSelectedAccountIds,
  getSelectedAccountFarms
)
